import React from 'react'

import PropTypes from 'prop-types'

import './article.css'

const Article = (props) => {
  return (
    <section className={`article-card ${props.rootClassName} `}>
      <main className="article-content">
        <header className="article-header">
          <h1 className="article-header1">{props.Header}</h1>
        </header>
        <p className="article-description">{props.Description}</p>
        <div className="article-button"></div>
      </main>
    </section>
  )
}

Article.defaultProps = {
  rootClassName: '',
  Description:
    'As mensagens são criptografadas em seu dispositivo para que o APP não possa acessá-las diretamente. A única solução disponível é lê-los nas notificações que você recebe e criar um backup de mensagens com base no seu histórico de notificações. Quando o APP detecta que uma mensagem foi excluída, ele imediatamente mostra uma notificação!',
  Header: 'RECUPERAR',
}

Article.propTypes = {
  rootClassName: PropTypes.string,
  Description: PropTypes.string,
  Header: PropTypes.string,
}

export default Article
