import React from 'react'

import { Helmet } from 'react-helmet'

import Article from '../components/article'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>WAMR</title>
        <meta property="og:title" content="WAMR" />
      </Helmet>
      <section className="home-section">
        <div className="home-hero">
          <div className="home-content">
            <main className="home-main">
              <header className="home-header">
                <h1 className="home-heading">
                  <span>Acessar o APP.</span>
                  <br></br>
                  <span> 100% Digital.</span>
                  <br></br>
                </h1>
                <span className="home-caption">
                  <span>
                    Quão irritante é quando seus amigos excluem suas mensagens
                    antes que você possa vê-los?
                  </span>
                  <br></br>
                  <span>
                    A curiosidade toma conta. Você acabou de encontrar a
                    solução.
                  </span>
                </span>
              </header>
              <div className="home-container1">
                <div className="home-container2">
                  <div className="home-buttons"></div>
                </div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.drilens.wamr&amp;hl=pt_BR"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link"
                >
                  <div className="home-get-started button">
                    <span className="home-text07">
                      <span>Acessar o App</span>
                      <br></br>
                    </span>
                  </div>
                </a>
              </div>
            </main>
            <div className="home-highlight">
              <div className="home-avatars">
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1552234994-66ba234fd567?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDN8fHBvdHJhaXR8ZW58MHx8fHwxNjY3MjQ0ODcx&amp;ixlib=rb-4.0.3&amp;w=200"
                  className="home-image avatar"
                />
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1610276198568-eb6d0ff53e48?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHBvdHJhaXR8ZW58MHx8fHwxNjY3MjQ0ODcx&amp;ixlib=rb-4.0.3&amp;w=200"
                  className="home-image1 avatar"
                />
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1618151313441-bc79b11e5090?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEzfHxwb3RyYWl0fGVufDB8fHx8MTY2NzI0NDg3MQ&amp;ixlib=rb-4.0.3&amp;w=200"
                  className="home-image2 avatar"
                />
              </div>
              <label className="home-caption1">
                <span>
                  Usado por pessoas como você.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>
                  Mais 50 milhões de downloads.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </label>
            </div>
          </div>
          <div className="home-image3">
            <img
              alt="image"
              src="/playground_assets/whats-1500h.webp"
              className="home-image4"
            />
          </div>
          <div className="home-image5">
            <img
              alt="image"
              src="/playground_assets/whats-1500h.webp"
              className="home-image6"
            />
          </div>
        </div>
      </section>
      <section className="home-section1">
        <header className="home-header1">
          <span className="section-head">
            <span>COMO FUNCIONA</span>
            <br></br>
          </span>
        </header>
        <main className="home-cards">
          <Article rootClassName="article-root-class-name"></Article>
          <section className="home-card">
            <main className="home-content1">
              <header className="home-header2">
                <h1 className="home-header3">
                  MENSAGENS
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </h1>
              </header>
              <p className="home-description">
                O APP também tentará salvar qualquer mídia anexada à mensagem e,
                se o remetente a excluir, você receberá uma notificação.
              </p>
              <div className="home-button"></div>
            </main>
          </section>
          <section className="home-card1">
            <main className="home-content2">
              <header className="home-header4">
                <h1 className="home-header5"> MÍDIA</h1>
              </header>
              <p className="home-description1">
                <span>
                  Os seguintes tipos de mídia podem ser recuperados: fotos,
                  vídeos, gifs animados, áudio, anotações de voz, documentos,
                  adesivos.
                </span>
                <br></br>
                <br></br>
              </p>
              <div className="home-button1"></div>
            </main>
          </section>
        </main>
      </section>
      <section className="home-section3">
        <main className="home-content3">
          <header className="home-header6">
            <h2 className="home-heading1 section-heading">
              <span>Começar imediatamente.</span>
              <br></br>
            </h2>
            <div className="home-buttons1">
              <a
                href="https://play.google.com/store/apps/details?id=com.drilens.wamr&amp;hl=pt_BR"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link1"
              >
                <div className="home-i-o-s button">
                  <img
                    alt="image"
                    src="/playground_assets/apple-200w.png"
                    className="home-icon"
                  />
                  <span className="home-text20">Download for iOS</span>
                </div>
              </a>
              <div className="home-android button">
                <img
                  alt="image"
                  src="/playground_assets/android-200h.png"
                  className="home-icon1"
                />
                <a
                  href="https://play.google.com/store/apps/details?id=com.drilens.wamr&amp;hl=pt_BR"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-text21"
                >
                  Download for Android
                </a>
              </div>
            </div>
          </header>
        </main>
      </section>
      <footer className="home-footer">
        <div className="home-content4">
          <main className="home-main-content">
            <div className="home-content5">
              <header className="home-main1">
                <div className="home-header7"></div>
                <span className="home-text22">
                  Sua segurança começa com o entendimento de como os
                  desenvolvedores coletam e compartilham seus dados. As práticas
                  de segurança e privacidade de dados podem variar de acordo com
                  o uso, a região e a idade. O desenvolvedor forneceu as
                  informações a seguir, que podem ser atualizadas ao longo do
                  tempo.
                </span>
                <div className="home-socials">
                  <a
                    href="https://example.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link2"
                  >
                    <img
                      alt="image"
                      src="/playground_assets/linkedin-200h.png"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://example.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link3"
                  >
                    <img
                      alt="image"
                      src="/playground_assets/instagram-200h.png"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://example.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link4"
                  >
                    <img
                      alt="image"
                      src="/playground_assets/twitter-200h.png"
                      className="social"
                    />
                  </a>
                </div>
              </header>
              <header className="home-categories"></header>
            </div>
            <section className="home-copyright">
              <span className="home-text23">
                © 2022 WAMR. All Rights Reserved.
              </span>
            </section>
          </main>
          <main className="home-subscribe">
            <main className="home-main2">
              <h1 className="home-heading2">Faça parte da nossa newsletter</h1>
              <div className="home-input-field">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="home-textinput input"
                />
                <div className="home-buy button">
                  <span className="home-text24">-&gt;</span>
                  <span className="home-text25">
                    <span>Subscribe now</span>
                    <br></br>
                  </span>
                </div>
              </div>
            </main>
            <h1 className="home-notice">
              Ao se inscrever a nossa newsletter está a concordar com os nossos
              Termos e Condições.
            </h1>
          </main>
          <section className="home-copyright1">
            <span className="home-text28">
              © 2022 latitude. All Rights Reserved.
            </span>
          </section>
        </div>
      </footer>
    </div>
  )
}

export default Home
